import {
  createPortalUserMutation,
  portalUserQuery,
  updatePortalUserMutation,
} from 'src/services/PortalUsers/PortalUserGraphQL';

import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {IPortalUser} from "../../types/PortalUser";
import {Auth0UsernamePasswordConnection} from "../../utils/constants";
import {graphQLClient} from "../shared/GraphQLService";
import {transformCompanyAccess} from "../shared/ClientService";

export const getPortalUser = async ({accessToken, parameters}:{
  accessToken: string,
  parameters: any}) : Promise<IPortalUser> => {
  const {external_id} = parameters;
  const theQuery = async (client: any) => {
    const result = client.request(
      portalUserQuery,
      {
        user_filter: {external_id: {_eq:external_id}}
      }
    );
    return result;
  }
  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken
    );
  } catch (error) {
    console.error('getPortalUser failed')
    throw (error)
  }

  if (!response || !response.cfg_user || response.cfg_user.length !== 1) {
    throw new Error('Could not load user');
  }

  return transformPortalUser(response.cfg_user[0]);
}

const transformPortalUser = (user: any) => {
  return {
    id: user.pub_id,
    name: user.name,
    email: user.email,
    disabled: user.is_disabled,
    connectionType: user.connection_type,
    clientRoles: user.user_roles.map((cr:any) => {
      return {
        role:cr.role,
        client: transformCompanyAccess(cr.client),
      }})
  }
}

export const getPortalUsers = async ({accessToken}:{accessToken: string}) : Promise<IPortalUser[]> => {
  const theQuery = async (client: any) => {
    const result = client.request(
      portalUserQuery,
      {
        user_filter: {}
      }
    );
    return result;
  }
  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken
    );
  } catch (error) {
    console.error('getPortalUser failed')
    throw (error)
  }

  return response.cfg_user.map((u: any) => {
    return transformPortalUser(u);
  });
}

export const updatePortalUser = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    portalUser
  } = parameters;

  if (!portalUser) throw new Error('existing portal user required');

  const client = await graphQLClient({accessToken: auth0HasuraAccessToken});

  let inputVars:{id: string, client_roles: any[], disabled: boolean, name?: string, email?: string} = {
    id: portalUser.id,
    disabled: portalUser.disabled,
    client_roles:portalUser.clientRoles
  };

  // We only want to include the name and email if the connection is "Username-Password-Authentication"
  if (portalUser.connectionType === Auth0UsernamePasswordConnection) {
    inputVars.name = portalUser.name;
    inputVars.email = portalUser.email;
  }

  const variables = {
    client_id: client_id,
    req: inputVars
  };

  const result = await client?.request(
    updatePortalUserMutation,
    variables
  )

  if (result.error) {
    console.error(result.error)
    throw new Error(result.error.message)
  };

  return result;
}

export const createPortalUser = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    portalUser
  } = parameters;

  if (!portalUser) throw new Error('portal user required');

  const client = await graphQLClient({accessToken: auth0HasuraAccessToken});

  const variables = {
    client_id: client_id,
    req: {
      name: portalUser.name,
      email: portalUser.email,
      client_roles:portalUser.clientRoles,
      disabled: portalUser.disabled,
      initiate_password_reset: portalUser.forcePasswordReset,
    }
  };

  const result = await client?.request(
    createPortalUserMutation,
    variables
  )
  return result;
}

import React, {FC, useState} from 'react';
import {MainContent} from 'src/components/MainLayout/MainContent';
import {MainLayoutFooter} from 'src/components/MainLayout/MainLayoutFooter';
import {Header} from 'src/components/Header/Header';
import {GetLoggedInUser, PortalContext} from "../../hooks/PortalContext";
import {useUpgradeFeatureDialog} from "../common/UpgradeFeature/useUpgradeFeatureDialog";
import {planTypes} from "../../utils/constants";
import {ICompanyAccess} from "../../types/Company";
import {NoAuthLayout} from "./NoAuthLayout";
import {Typography} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import {DefaultButton} from "../common/DefaultButton";

export const MainLayout: FC = ({ children }: any) => {
  let savedClientId = localStorage.getItem("client_pub_id");
  const [currentClientId, setCurrentClientId] = useState<string | null>(savedClientId);

  // Set up the feature upgrade dialog
  const {
    dialog: featureUpgradeDialog,
    setIsOpen: setFeatureDialogOpen,
  } = useUpgradeFeatureDialog({
    onCancel: ()=>{setFeatureDialogOpen(false)},
  })

  let {user, isLoading} = GetLoggedInUser();

  if (isLoading || !user) {
    return <></>
  }

  // A user needs to have at least one company or the portal is useless
  // So if they don't have any companies, show a message and block them going further
  if (user.accessTo.length === 0) {
    return <NoCompany />
  }

  // Find the current client from the list of user roles
  let clientInfo = user.accessTo.find((client: ICompanyAccess) => client.id === currentClientId);
  if(!clientInfo) {
    // If it can't find it, or one hasn't been set, then find the first one
    clientInfo = user.accessTo[0];
    localStorage.setItem("client_pub_id", clientInfo.id);
  }

  let portalContext = {
    clientInfo:clientInfo,
    loggedInUser: user,
    isTrial: clientInfo.planType === planTypes.Trial,
    setClient: (client_id : string) => {
        localStorage.setItem("client_pub_id", client_id);
        setCurrentClientId(client_id);
    },
    featureDialog: {
      open: () => setFeatureDialogOpen(true),
      close: () => setFeatureDialogOpen(false),
    }
  }

  return (
    <PortalContext.Provider value={portalContext}>
      {featureUpgradeDialog}
      <Header />
      <MainContent>{children}</MainContent>
      <MainLayoutFooter />
    </PortalContext.Provider>
  );
};

// Message to show when a user doesn't have a company
const NoCompany = () => {
  const {logout} = useAuth0();

  return (
    <NoAuthLayout>
      <Typography>You don't appear to have a subscription to Detexian. If you feel this is in error, email <a href={"mailto:support@detexian.com"} >support@detexian.com</a></Typography>
      <DefaultButton variant="contained" onClick={() => logout({returnTo: window.location.origin})} style={{marginTop: '20px'}}>Switch user</DefaultButton>
    </NoAuthLayout>)
}

import {ICompanyAccess} from "../types/Company";
import {createContext, useContext} from "react";
import {IPortalUser} from "../types/PortalUser";
import {useAuth0Token} from "./Auth0Hooks";
import {useQueryWithAccessTokenWithoutContext} from "./TanstackHooks";
import {cacheKeys} from "../services/shared/serviceConstants";
import {getPortalUser} from "../services/PortalUsers/PortalUserQueries";
import {portalUserRoles} from "../utils/constants";
import {getAllClientsWithFeatures} from "../services/Clients/ClientsQueries";

type PortalInfo = {
  clientInfo: ICompanyAccess,
  loggedInUser: {userInfo: IPortalUser, highestPrivilege: string, accessTo: ICompanyAccess[]},
  isTrial: boolean,
  setClient: (clientId: string) => void,
  featureDialog?: {
    open: () => void,
    close: () => void,
  }
}
export const PortalContext = createContext<PortalInfo | null>(null);

export const usePortalContext = () => {
  const ctx = useContext(PortalContext);

  if (!ctx) {
    throw new Error(
      "usePortalContext has to be used within <PortalContext.Provider>"
    );
  }

  return ctx;
};

export const GetLoggedInUser = (): {
  user: {userInfo: IPortalUser, highestPrivilege: string, accessTo: ICompanyAccess[]} | undefined,
  isLoading: boolean
} => {
  // Get the Auth0 user because we need the user_id/sub to get the portal user
  const {user, isLoading: tokenLoading} = useAuth0Token();

  // Get the logged in user using the Auth0 user_id (which is in the "sub" field)
  const {data: userInfo, isLoading: userInfoLoading} = useQueryWithAccessTokenWithoutContext({
    queryKey: [cacheKeys.loggedInUser],
    queryFunction: getPortalUser,
    queryFunctionVariables: {
      external_id: user?.sub
    },
    tanstackOptions: {
      enabled: !tokenLoading,
    }});


  const {data: clientData, isLoading: isClientLoading}  = useQueryWithAccessTokenWithoutContext({
      queryKey: [cacheKeys.clients],
      queryFunction: getAllClientsWithFeatures,
      queryFunctionVariables: {}
    }
  );

  // Return with the loading state if the above queries are still loading
  if (userInfoLoading || tokenLoading || isClientLoading) {
    return {user: undefined, isLoading: true};
  }

  // Pages/Features in the portal are restricted by the user's role
  // If a user has ANY role of a higher permission, they can see the higher permission pages/features
  // Start by defaulting them to a "user"
  let highestPrivilege = portalUserRoles.user;

  if (userInfo.clientRoles.some((cr: any) => cr.role === portalUserRoles.admin)) {
    // If they're an "admin" (Super Admin) in any role, they can see the admin pages/features
    highestPrivilege = portalUserRoles.admin;
  } else if (userInfo.clientRoles.some((cr: any) => cr.role === portalUserRoles.orgAdmin)) {
    // Otherwise, If they're an "orgAdmin" (Account Admin) in any role, they can see the orgAdmin pages/features
    highestPrivilege = portalUserRoles.orgAdmin;
  }

  return {user : {userInfo: userInfo, highestPrivilege: highestPrivilege, accessTo: clientData}, isLoading: false};
}